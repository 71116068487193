import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`The bike season is in full swing. After the first warm up runs on the home
trails, soon the question comes up where to go for this years bike holiday?
Which weekend trips could be good? Do you want to sit in the car for hours,
even take the plane, or are there maybe also good spots nearby?`}</strong></p>
    <br />
    <Image src="news/userawards0619.jpg" mdxType="Image" />
    <p>{`To be honest, I lost interest in driving many hours, long time ago. The
classic trip to Finale Ligure costs a lot of time and nerves. Traffic jams at
the Gotthard tunnel, or around Milano, forced stops in little Italian garages
to get the car fixed. And then there is the bad conscience. Not a single day
goes by, without reading something about the climate change, about the
necessary change in behaviour of everyone of us. Do I really want to drive
hundreds of kilometers, multiple times a year, just to do a sport that is very
close to nature?`}</p>
    <br />
    <p>{`I live in Freiburg, Germany, in the Black Forest, for a few years now, known
for its great trails, the dedicated biking scene, and for the engagement of
the local mountain bike club. I really can’t complain about the lack of
trails. And the great thing with biking is, that it can be so uncomplicated.
Just grab your bike, and start riding from home.`}</p>
    <br />
    <p>{`Nevertheless, I always ride here, it is nothing special anymore, and
suddenly my thoughts go to the destinations far away. The Alps, of course,
the Italian coast, yes, Spanish and Portuguese islands, very much! But isn’t
there anything nearby?`}</p>
    <Image src="news/userawards0619-2.jpg" mdxType="Image" />
    <p>{`Between Freiburg and the bike spots further away, a gray veil lays on my inner
map. But I am sure there have to be many beautiful trails I don’t know yet.
The Vosges mountains of the Alsace in France are in sight, to the south the
Black Forest rises up to the Swiss border, in the north I have heard of nice
trails in villages like Sasbachwalden or Baiersbronn.`}</p>
    <br />
    <p>{`The MTB Freiburg
club and locals shared more than 40 trails around Freiburg. Europe wide there
are more than 3.000 trails on Trailguide. Many more possibilities come up when
everyone shares the local trails, and the whole bike community works together
on a trail map. The long, nerve-racking, and environmental impacting trip
suddenly becomes less interesting. Many kilometers are not driven, the local
tourism supported.`}</p>
    <br />
    <p>{`More possibilities also relieve the trails, and
enable a more sustainable usage. Instead of many bikers, that use a few well
known trails, the traffic and the impact spreads to many trails.`}</p>
    <Image src="news/uploadtrails_6.jpg" mdxType="Image" />
    <p>{`Again, Trailguide is going to award the users who contribute to the public
trail-map. Partner of the awards is Norrøna who provide the winners with a
bike jacket and a backpack. Everyone who uploads a trail to
`}<a parentName="p" {...{
        "href": "https://www.trailguide.net"
      }}>{`trailguide.net`}</a>{` until the 15th of August
participates in the drawing.`}</p>
    <br />
    <p>{`On top of that Norrøna gives you additional Cash Points on your next
purchase on `}<a parentName="p" {...{
        "href": "https://www.norrona.com"
      }}>{`norrona.com`}</a>{` when you use our promo
code - Trailguide -`}</p>
    <Image src="news/uploadtrails_5.jpg" mdxType="Image" />
    <p>{`With everyone contributing a little bit, Trailguide gets better and better
everyday. We are looking forward for your tracks, and with a bit of luck, you
will have a nice backpack and jacket from
`}<a parentName="p" {...{
        "href": "https://www.norrona.com"
      }}>{`Norrøna`}</a>{` on your next ride!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      